import * as types from '@/redux/actionTypes'
import produce from 'immer'
// import _ from 'lodash'

const initialState = {
  // all_items_in_stock: true,
  base_amount: 0,
  cart_amount: 0,
  channel_id: 0,
  total_item: 0,
  coupons: [],
  created_time: '',
  currency: {},
  customer_id: 0,
  discount_amount: 0,
  email: '',
  id: '',
  line_items: {
    custom_items: [],
    digital_items: [],
    gift_certificates: [],
    physical_items: [],
  },
  locale: 'en',
  tax_included: false,
  updated_time: '',
  all_cart_items: [],
  updated: false,
}

const ApiRecipe = (draft = initialState, action) => {
  switch (action.type) {
    case types.LOAD_CART_SUCCESS:
      return {
        ...draft,
        ...action.payload,
      }
    case types.ADD_CART_SUCCESS:
      return {
        ...draft,
        ...action.payload,
      }
    case types.DELETE_CART_SUCCESS:
      return {
        ...draft,
        ...action.payload,
      }
    case types.UPDATE_CART_SUCCESS:
      draft = action.payload
      return draft
    case types.CLEAR_CART_SUCCESS:
      return initialState
    // case types.DELETE_CART_SUCCESS:
    //   if (_.isEmpty(action.payload.data)) {
    //     draft = {
    //       ...initialState,
    //       all_cart_items: action.payload.newAllCartItems
    //     }
    //   } else {
    //     draft = {
    //       ...action.payload.data,
    //       all_cart_items: action.payload.newAllCartItems
    //     }
    //   }
    //   return draft
    case types.UPDATE_CART_SUCCESS:
      draft = action.payload
      return draft
    // case types.UPDATE_ITEM_QTY_SUCCESS:
    //   const { itemId, quantity } = action.payload
    //   const res = draft.line_items.physical_items.find((ele) => ele.id == itemId)
    //   res.quantity = quantity
    //   console.log('res', res)
    //   return draft
    // case types.UPDATE_ALL_CART_ITEMS_SUCCESS:
    //   return {
    //     ...draft,
    //     all_cart_items: action.payload
    //   }
    default:
      return draft
  }
}

export const cartReducer = produce(ApiRecipe)
