import { useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import Head from 'next/head'
import dynamic from 'next/dynamic'
import Cookies from 'js-cookie'

import { store, persistor } from '../redux/store/configureStore'
import { PersistGate } from 'redux-persist/integration/react'
import '../styles/globals.css'
// import '../datadog'
import { useRouter } from 'next/router'

// Attributes global state
const ToastSyle = dynamic(async () =>
  import('@/components/atoms/Toast').then((module) => module.ToastSyle)
)
const AttributeProvider = dynamic(async () =>
  import('@/components/context/AttributeContext').then((module) => module.AttributeProvider)
)
const MobileSearchProvider = dynamic(async () =>
  import('@/components/context/MobileSearchContext').then((module) => module.MobileSearchProvider)
)
const SystemBuilderProvider = dynamic(async () =>
  import('@/components/context/SystemBuilderContext').then((module) => module.SystemBuilderProvider)
)
const UserDetailProvider = dynamic(async () =>
  import('@/components/context/UserDetailContext').then((module) => module.UserDetailProvider)
)
const ToastProvider = dynamic(async () =>
  import('react-toast-notifications').then((module) => module.ToastProvider)
)
const RouteProvider = dynamic(async () =>
  import('@/components/context/routerContext').then((module) => module.RouteProvider)
)

const Script = dynamic(() => import('next/script'))

const AppProvider = ({
  Component,
  pageProps,
  attributeToEdit,
  attributes,
  categories,
  categoryToEdit,
  setAttributeToEdit,
  setAttributes,
  setCategories,
  setCategoryToEdit,
  setSystemSettings,
  setSystemToEdit,
  setSystems,
  systemSettings,
  systemToEdit,
  systems,
  setIsOpenMenu,
  isOpenMenu,
}) => {
  const isProd = process.env.NEXT_PUBLIC_ENVIRONMENT === 'prod'
  const version = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA?.slice(-7)
  const router = useRouter()

  const parseUTMParams = () => {
    const url = new URL(window.location.href)
    const searchParams = url.searchParams
    const utmParams = {}
    searchParams.forEach((value, key) => {
      utmParams[key] = value
    })
    const keyValuePairs = []
    for (let key in utmParams) {
      if (utmParams.hasOwnProperty(key)) {
        if (key.startsWith('utm_')) {
          const encodedKey = encodeURIComponent(key)
          const encodedValue = encodeURIComponent(utmParams[key])
          keyValuePairs.push(`${encodedKey}=${encodedValue}`)
        }
      }
    }
    return keyValuePairs.length > 0 ? keyValuePairs.join('&') : []
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const pixelParams = parseUTMParams()
      if (pixelParams.length > 0) {
        window.localStorage.setItem('utmParams', pixelParams)
      } else {
        window.localStorage.setItem('utmParams', '')
      }
      setInterval(() => {
        if(typeof OnetrustActiveGroups != 'undefined') {
          if(!OnetrustActiveGroups.includes('C0002')) {
            // Iterate over all cookies
            Object.keys(Cookies.get()).forEach(function(cookieName) {
              // Check if the cookie domain contains 'yotpo'
              if (cookieName.indexOf('yotpo') !== -1) {
                  // Remove the cookie
                  Cookies.remove(cookieName);
                  Cookies.remove('pixel');
                  console.log('Cookie removed:', cookieName);
              }
            });
          }
        }
      }, 3000);
    }
  }, [])

  return (
    <>
      <Head>
        <title>SITKA Gear | Turning Clothing Into Gear</title>
        <meta name="sitecheckerpro-site-verification" content="863d9382f47b3bc05c36221dffbe5a3e" />
        <meta name="viewport" content="width=device-width, initial-scale=1 maximum-scale=1" />
        <meta
          name="description"
          content="SITKA Gear uses advanced design, technology and fabrics to create gear systems that enhance the experience of the hunter."
        />
        <meta
          name="keywords"
          content="hunting apparel, deer hunting, big game hunting, elk hunting gear, elk gear, sheep hunting, sitka, sitka gear, technical gear, gear, hunting gear, backcountry hunting, waterproof hunting clothing, hunting clothing, outdoors gear, sitka clothing, sitka gear hunting, waterfowl hunting, gore-tex gear, windstopper, primaloft"
        />
        <link rel="icon" href="/images/favicon/favicon.ico" sizes="32x32" />
        <link rel="icon" href="/images/favicon/favicon.svg" type="image/svg+xml" />
        <link rel="apple-touch-icon" href="/images/favicon/favicon-apple-touch-icon.png" />
        <link rel="manifest" href="/manifest.webmanifest" />
      </Head>
      <RouteProvider>
        <SystemBuilderProvider>
          <UserDetailProvider>
            <ToastProvider autoDismiss autoDismissTimeout={3000} components={{ Toast: ToastSyle }}>
              <AttributeProvider
                value={{
                  attributeToEdit,
                  attributes,
                  categories,
                  categoryToEdit,
                  setAttributeToEdit,
                  setAttributes,
                  setCategories,
                  setCategoryToEdit,
                  setSystemSettings,
                  setSystemToEdit,
                  setSystems,
                  systemSettings,
                  systemToEdit,
                  systems,
                  setIsOpenMenu,
                  isOpenMenu,
                }}
              >
                <MobileSearchProvider>
                  <Component {...pageProps} />
                  {/* datadog for prod */}
                  {isProd && (
                    <Script id="datadog-script">
                      {`
                              (function(h,o,u,n,d) {
                              h=h[d]=h[d]||{q:[],onReady:function(c){h.q.push(c)}}
                              d=o.createElement(u);d.async=1;d.src=n
                              n=o.getElementsByTagName(u)[0];n.parentNode.insertBefore(d,n)
                              })(window,document,'script','https://www.datadoghq-browser-agent.com/us1/v4/datadog-rum.js','DD_RUM')
                              window.DD_RUM.onReady(function() {
                              window.DD_RUM.init({
                              applicationId: '59403014-caa9-4d5f-b781-64b33b7c6829',
                              clientToken: 'pub853f8e33002cb36bc8127f094b8efdfe',
                              site: 'datadoghq.com',
                              service:'sitkagear-rum',
                              env:'prod',
                              // Specify a version number to identify the deployed version of your application in Datadog
                              version: '${version}',
                              sessionSampleRate:100,
                              sessionReplaySampleRate: 10,
                              trackUserInteractions: true,
                              trackResources: true,
                              trackLongTasks: true,
                              defaultPrivacyLevel:'mask-user-input'
                              })
                              window.DD_RUM.startSessionReplayRecording();
                            })
                          `}
                    </Script>
                  )}
                  {/* / */}
                  {/* <!-- Yotpo --> */}
                  {/* {!devMode && ( */}
                  <iframe
                    id="iframe-pixel"
                    src={`${process.env.NEXT_PUBLIC_BIGCOMMERCE_STORE_URL}/pixel`}
                    style={{
                      visibility: 'hidden',
                      width: '1px',
                      height: '1px',
                      overflow: 'hidden',
                    }}
                  ></iframe>
                  {/* )} */}
                  {/* <Script
                    id="load-yotpo-script"
                    strategy="afterInteractive"
                    dangerouslySetInnerHTML={{
                      __html: `
                        function loadYotpo() {
                          var e = document.createElement("script");
                          e.type = "text/javascript", e.defer = true, e.src = "//staticw2.yotpo.com/${process.env.NEXT_PUBLIC_YOTPO_API_KEY}/widget.js?preventCookies=true";
                          var t = document.getElementsByTagName("script")[0];
                          t.parentNode.insertBefore(e, t)
                        }

                        setTimeout(function() {
                          loadYotpo()
                        },5000)
                      `,
                    }}
                  /> */}
                  {/* <!-- END Yotpo --> */}
                  {/* SITKA-2397 : Comment code  */}
                  {/* {!isOpenMenu && <NewsletterModal />} */}
                </MobileSearchProvider>
              </AttributeProvider>
            </ToastProvider>
          </UserDetailProvider>
        </SystemBuilderProvider>
      </RouteProvider>
    </>
  )
}

function MyApp({ Component, pageProps }) {
  const [attributes, setAttributes] = useState([])
  const [attributeToEdit, setAttributeToEdit] = useState(null)
  const [categories, setCategories] = useState([])
  const [categoryToEdit, setCategoryToEdit] = useState(null)
  const [systemSettings, setSystemSettings] = useState({})
  const [systemToEdit, setSystemToEdit] = useState(null)
  const [systems, setSystems] = useState([])
  const [isOpenMenu, setIsOpenMenu] = useState(false)
  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    if (typeof window != 'undefined') {
      setIsClient(true)
    }
  }, [])

  if (!isClient) {
    return (
      <>
        <Provider store={store}>
          <AppProvider
            attributes={attributes}
            setAttributes={setAttributes}
            attributeToEdit={attributeToEdit}
            setAttributeToEdit={setAttributeToEdit}
            categories={categories}
            setCategories={setCategories}
            categoryToEdit={categoryToEdit}
            setCategoryToEdit={setCategoryToEdit}
            systemSettings={systemSettings}
            setSystemSettings={setSystemSettings}
            systemToEdit={systemToEdit}
            setSystemToEdit={setSystemToEdit}
            systems={systems}
            setSystems={setSystems}
            isOpenMenu={isOpenMenu}
            setIsOpenMenu={setIsOpenMenu}
            Component={Component}
            pageProps={pageProps}
          />
        </Provider>
      </>
    )
  } else if (isClient) {
    return (
      <>
        <Provider store={store}>
          <PersistGate persistor={persistor} loading={null}>
            <AppProvider
              attributes={attributes}
              setAttributes={setAttributes}
              attributeToEdit={attributeToEdit}
              setAttributeToEdit={setAttributeToEdit}
              categories={categories}
              setCategories={setCategories}
              categoryToEdit={categoryToEdit}
              setCategoryToEdit={setCategoryToEdit}
              systemSettings={systemSettings}
              setSystemSettings={setSystemSettings}
              systemToEdit={systemToEdit}
              setSystemToEdit={setSystemToEdit}
              systems={systems}
              setSystems={setSystems}
              isOpenMenu={isOpenMenu}
              setIsOpenMenu={setIsOpenMenu}
              Component={Component}
              pageProps={pageProps}
            />
          </PersistGate>
        </Provider>
      </>
    )
  }
}

export default MyApp
